.scroll-to-top {
  align-items: center;
  background-color: rgba(51, 51, 51, 0.3);
  border-radius: 10px;
  bottom: 20px;
  display: block;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  opacity: 0;
  position: fixed;
  right: 40px;
  transition: all .25s ease-in-out;
  visibility: hidden;
  width: 50px;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.button {
  &.is-small {
    border-radius: 4px;

    &.decision-btn {
      border-radius: 8px;
    }
  }

  &.calendar-btn {
    align-items: center;
    color: #fff;
    justify-content: space-evenly;

    &.apple {
      background-color: #000;
    }

    &.microsoft {
      background-color: #00a2ed;
    }

    &.google {
      background-color: #1167bf;
    }
  }
}

.booking-item-content {
  width: 100%;
}

.hero-heading {
  align-items: center;
  display: flex;

  .logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 1023px) {
  .hero .hero-body {
    padding: 1.5rem;
  }

  .add-to-cal-btns {
    .column {
      padding-bottom: 0.25em;
      padding-top: 0.25em;
    }
  }

  .button {
    &.calendar-btn {
      svg {
        left: 2em;
        position: absolute;
      }
    }
  }

  .hero-heading {
    .logo {
      height: 60px;
      width: 60px;
    }
  }
}
