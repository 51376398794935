.webcam-mainview {
  height: 50vh;
}

.call-controls {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.meeting-view {
  background-color: black;
  height: 75vh;
  position: relative;
}

.room-participant-webcam {
  height: 75vh;
}

.webcam-preview {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
  max-width: 120px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25%;
}
