$primaryColour: #4a4a4a;

$section-padding: 0.5rem 1.5rem;
$footer-padding: 1.5rem;

$modal-card-head-padding: 10px;
$modal-card-title-size: 1rem;
$modal-card-body-padding: 15px;
$modal-card-head-radius: 2px;
$modal-card-foot-radius: 2px;
