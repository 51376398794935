.slot-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  &::after {
    border-bottom: 1px solid $primaryColour;
    border-right: 1px solid $primaryColour;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    transform: rotate(-45deg);
  }

  .slot-item-date {
    color: $primaryColour;
    margin-right: auto;
  }
}

.slot-date,
.slot-address,
.slot-name {
  display: flex;
  align-items: flex-start;
}
